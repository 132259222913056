<script lang="ts">import { Route, router } from 'tinro';
import { activeSession, appReady, darkMode } from '~/store';
import { isRelease } from '~/config';
import Page from '~/components/layout/page.svelte';
import Login from '~/pages/login.svelte';
import Dashboard from '~/pages/dashboard/index.svelte';
import Earn from '~/pages/earn/index.svelte';
import Request from '~/pages/request/index.svelte';
import Send from '~/pages/send/index.svelte';
import TokensPurchase from '~/pages/tokens/purchase/index.svelte';
import Resources from '~/pages/resources/index.svelte';
import Components from '~/pages/_components/index.svelte';
import Loading from '~/pages/loading.svelte';
import Toasts from '~/components/elements/toasts.svelte';
import BanxaSuccess from '~/pages/banxa/success.svelte';
import BanxaFailure from '~/pages/banxa/failure.svelte';
import Transfer from '~/pages/transfer/index.svelte';
$: {
    document.body.classList.toggle('darkmode', $darkMode);
    if ($darkMode) {
        document
            .querySelector('meta[name=theme-color]')
            ?.setAttribute('content', needLogin ? '#101010' : '#1c1c1e');
    }
    else {
        document
            .querySelector('meta[name=theme-color]')
            ?.setAttribute('content', needLogin ? '#ececec' : '#ffffff');
    }
}
$: needLogin =
    $activeSession === undefined &&
        !$router.path.startsWith('/_components') &&
        !$router.path.startsWith('/request');
</script>

<style lang="scss" global>:global(*),
:global(*:before),
:global(*:after) {
  box-sizing: border-box;
}

:global(html),
:global(body),
:global(div),
:global(span),
:global(object),
:global(iframe),
:global(figure),
:global(h1),
:global(h2),
:global(h3),
:global(h4),
:global(h5),
:global(h6),
:global(p),
:global(blockquote),
:global(pre),
:global(a),
:global(code),
:global(em),
:global(img),
:global(small),
:global(strike),
:global(strong),
:global(sub),
:global(sup),
:global(tt),
:global(b),
:global(u),
:global(i),
:global(ol),
:global(ul),
:global(li),
:global(fieldset),
:global(form),
:global(label),
:global(table),
:global(caption),
:global(tbody),
:global(tfoot),
:global(thead),
:global(tr),
:global(th),
:global(td),
:global(main),
:global(canvas),
:global(embed),
:global(footer),
:global(header),
:global(nav),
:global(section),
:global(video) {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

:global(footer),
:global(header),
:global(nav),
:global(section),
:global(main) {
  display: block;
}

:global(body) {
  line-height: 1;
}

:global(ol),
:global(ul) {
  list-style: none;
}

:global(blockquote),
:global(q) {
  quotes: none;
}

:global(blockquote:before),
:global(blockquote:after),
:global(q:before),
:global(q:after) {
  content: "";
  content: none;
}

:global(table) {
  border-collapse: collapse;
  border-spacing: 0;
}

:global(input) {
  -webkit-appearance: none;
  border-radius: 0;
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("/fonts/inter/Inter-Thin.woff2?v=3.19") format("woff2"), url("/fonts/inter/Inter-Thin.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("/fonts/inter/Inter-ThinItalic.woff2?v=3.19") format("woff2"), url("/fonts/inter/Inter-ThinItalic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("/fonts/inter/Inter-ExtraLight.woff2?v=3.19") format("woff2"), url("/fonts/inter/Inter-ExtraLight.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("/fonts/inter/Inter-ExtraLightItalic.woff2?v=3.19") format("woff2"), url("/fonts/inter/Inter-ExtraLightItalic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/fonts/inter/Inter-Light.woff2?v=3.19") format("woff2"), url("/fonts/inter/Inter-Light.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("/fonts/inter/Inter-LightItalic.woff2?v=3.19") format("woff2"), url("/fonts/inter/Inter-LightItalic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/inter/Inter-Regular.woff2?v=3.19") format("woff2"), url("/fonts/inter/Inter-Regular.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/inter/Inter-Italic.woff2?v=3.19") format("woff2"), url("/fonts/inter/Inter-Italic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("/fonts/inter/Inter-Medium.woff2?v=3.19") format("woff2"), url("/fonts/inter/Inter-Medium.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("/fonts/inter/Inter-MediumItalic.woff2?v=3.19") format("woff2"), url("/fonts/inter/Inter-MediumItalic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("/fonts/inter/Inter-SemiBold.woff2?v=3.19") format("woff2"), url("/fonts/inter/Inter-SemiBold.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("/fonts/inter/Inter-SemiBoldItalic.woff2?v=3.19") format("woff2"), url("/fonts/inter/Inter-SemiBoldItalic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/fonts/inter/Inter-Bold.woff2?v=3.19") format("woff2"), url("/fonts/inter/Inter-Bold.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/fonts/inter/Inter-BoldItalic.woff2?v=3.19") format("woff2"), url("/fonts/inter/Inter-BoldItalic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("/fonts/inter/Inter-ExtraBold.woff2?v=3.19") format("woff2"), url("/fonts/inter/Inter-ExtraBold.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("/fonts/inter/Inter-ExtraBoldItalic.woff2?v=3.19") format("woff2"), url("/fonts/inter/Inter-ExtraBoldItalic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("/fonts/inter/Inter-Black.woff2?v=3.19") format("woff2"), url("/fonts/inter/Inter-Black.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("/fonts/inter/Inter-BlackItalic.woff2?v=3.19") format("woff2"), url("/fonts/inter/Inter-BlackItalic.woff?v=3.19") format("woff");
}
/* -------------------------------------------------------
Variable font.
Usage:

  html { font-family: 'Inter', sans-serif; }
  @supports (font-variation-settings: normal) {
    html { font-family: 'Inter var', sans-serif; }
  }
*/
@font-face {
  font-family: "Inter var";
  font-weight: 100 900;
  font-style: normal;
  font-named-instance: "Regular";
  font-display: swap;
  src: url("/fonts/inter/Inter-roman.var.woff2?v=3.19") format("woff2 supports variations(gvar)"), url("/fonts/inter/Inter-roman.var.woff2?v=3.19") format("woff2-variations"), url("/fonts/inter/Inter-roman.var.woff2?v=3.19") format("woff2");
}
@font-face {
  font-family: "Inter var";
  font-weight: 100 900;
  font-style: italic;
  font-named-instance: "Italic";
  font-display: swap;
  src: url("/fonts/inter/Inter-italic.var.woff2?v=3.19") format("woff2 supports variations(gvar)"), url("/fonts/inter/Inter-italic.var.woff2?v=3.19") format("woff2-variations"), url("/fonts/inter/Inter-italic.var.woff2?v=3.19") format("woff2");
}
/* --------------------------------------------------------------------------
[EXPERIMENTAL] Multi-axis, single variable font.

Slant axis is not yet widely supported (as of February 2019) and thus this
multi-axis single variable font is opt-in rather than the default.

When using this, you will probably need to set font-variation-settings
explicitly, e.g.

  * { font-variation-settings: "slnt" 0deg }
  .italic { font-variation-settings: "slnt" 10deg }

*/
@font-face {
  font-family: "Inter var experimental";
  font-weight: 100 900;
  font-style: oblique 0deg 10deg;
  font-display: swap;
  src: url("/fonts/inter/Inter.var.woff2?v=3.19") format("woff2-variations"), url("/fonts/inter/Inter.var.woff2?v=3.19") format("woff2");
}
:global(html) {
  font-family: "Inter", "system-ui", sans-serif;
  font-size: 13px;
}

@supports (font-variation-settings: normal) {
  :global(html) {
    font-family: "Inter var", "system-ui", sans-serif;
  }
}
:global(h1) {
  font-size: 24px;
  letter-spacing: -0.47px;
  font-weight: 600;
}

:global(h2) {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.18px;
}

:global(p) {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
}

:global(#greymass-wallet-version) {
  font-size: 0.2em;
  opacity: 0.2;
  position: fixed;
  bottom: 1em;
  right: 1em;
  pointer-events: none;
}

:global(html) {
  height: 100%;
  overflow: auto;
}

:global(label),
:global(p),
:global(h1),
:global(h2),
:global(h3),
:global(h4),
:global(h5),
:global(tr),
:global(td) {
  color: var(--main-black);
}

:global(a) {
  cursor: pointer;
}

:global(:root) {
  --main-white: #fff;
  --always-white: var(--main-white);
  --main-green: #26c64b;
  --main-grey: #f7f7fc;
  --main-red: #ff931e;
  --background-highlight: #fff;
  --background-highlight-hover: #fbfbfe;
  --dark-grey: #b7c1cb;
  --divider-grey: #e0e6ee;
  --light-black: #2c3e50;
  --light-blue: #e0eeff;
  --light-grey: #9898b5;
  --light-red: rgba(255, 146, 30, 0.1);
  --success-green: #4bca81;
  --error-red: #ff0033;
  --mobile-breakpoint: 600px;
  --melon: #f9c5b8;
  --sandy-brown: #ffa253;
  --light-goldenrod-yellow: #f5f1cc;
  --air-superiority-blue: #669bbc;
  --middle-blue-green: #7de8d1;
  --emerald: #62d385;
  --cultured: #eeeeee;
  --black: #000000;
  --white: #ffffff;
  --middle-green-eagle: #063a47;
  --lapis-lazuli: #005dac;
  --oxford-blue: #0a0e33;
  --brown-sugar: #996443;
  --antic-ruby: #82172e;
  --violet-crayola: #9d2c7a;
  --rich-black-FOGRA: #111111;
  --main-blue: var(--lapis-lazuli);
  --main-black: #585d6e;
}

:global(body.darkmode) {
  --main-white: #1c1c1e;
  --main-grey: #2c2c2e;
  --background-highlight: #3a3a3c;
  --background-highlight-hover: #3a3a3c57;
  --light-blue: #2a415e;
  --dark-grey: #8e8e93;
  --divider-grey: #3a3a3c;
  --melon: #063a47;
  /* middle green eagle */
  --sandy-brown: #005dac;
  /* lapis lazuli */
  --light-goldenrod-yellow: #0a0e33;
  /* oxford blue */
  --air-superiority-blue: #996443;
  /* brown sugar */
  --middle-blue-green: #82172e;
  /* antic ruby */
  --emerald: #9d2c7a;
  /* Violet Crayola */
  --cultured: #111111;
  /* Rich Black FOGRA */
  --black: #ffffff;
  --white: #000000;
  --middle-green-eagle: #f9c5b8;
  /* melon */
  --lapis-lazuli: #ffa253;
  /* sandy-brown */
  --oxford-blue: #f5f1cc;
  /* light-goldenrod-yellow */
  --brown-sugar: #669bbc;
  /* air-superiority-blue */
  --antic-ruby: #7de8d1;
  /* middle-blue-green */
  --violet-crayola: #62d385;
  /* emerald */
  --rich-black-FOGRA: #eeeeee;
  /* cultured */
  --main-blue: var(--middle-green-eagle);
  --main-black: #c4c4c4;
}

:global(body) {
  background: var(--main-white);
  color: var(--main-black);
  height: 100%;
}

:global(main) {
  min-height: 100vh;
}</style>

<svelte:head>
    <script
        async
        defer
        data-domain={isRelease ? 'wallet.greymass.com' : 'wallet-staging.greymass.com'}
        src="https://stats.greymass.com/js/plausible.exclusions.js"
        data-exclude="/account/*, /request/*">"use strict";
</script>
</svelte:head>
<main>
    {#if !$appReady}
        <Loading />
    {:else if needLogin}
        <Login />
    {:else}
        <Route>
            <Route path="/">
                <Dashboard />
            </Route>
            <Route path="/earn">
                <Earn />
            </Route>
            <Route path="/send">
                <Send />
            </Route>
            <Route path="/tokens/buy">
                <TokensPurchase />
            </Route>
            <Route path="/send/:contract/:token" let:meta>
                <Send {meta} />
            </Route>
            <Route path="/request/:payload">
                <Request />
            </Route>
            <Route path="/resources/*">
                <Resources />
            </Route>
            <Route path="/banxa/success">
                <BanxaSuccess />
            </Route>
            <Route path="/banxa/failure">
                <BanxaFailure />
            </Route>
            <Route path="/transfer">
                <Transfer />
            </Route>
            <Route fallback>
                <Page title="Page not found">
                    <p>You shouldn't be here. Get out before it's too late.</p>
                    <img src="/images/404.jpg" alt="404" />
                </Page>
            </Route>
            {#if !isRelease}
                <Route path="/_components/*">
                    <Components />
                </Route>
            {/if}
        </Route>
    {/if}
    <Toasts />
</main>
